.review-container{
  margin-top: 8rem;
 
  padding: 0;
}

.review-header{
    display:flex;
    justify-content: center;
    
}

.review-header > h1{
    color: var(--textDefault);
}

.review-content{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    gap:2rem;
    
}


.testimonial {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: calc(33.33% - 8rem);
  min-width: 300px;
  gap: 6rem;
  }

  .testimonial-card {
    border-radius: 1rem;
    box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--backgroundSecondary);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 38px 29px;
  }

  .star-rating {
    align-self: center;
    display: flex;
    gap: 0px;
  }
  .star-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    max-width: 40px;
  }
  .testimonial-content {
    display: flex;
    margin-top: 19px;
    align-items: end;
    gap: 0px;
    font-size: 20px;
    color: var(--textDefault);
    font-weight: normal;
  }
  .quote-icon-start {
    object-fit: auto;
    object-position: center;
    width: 29px;
    align-self: start;
  }
  .testimonial-text {
    font-style: italic;
    margin-top: 28px;
    flex-grow: 1;
    flex-basis: auto;
  }
  .quote-icon-end {
    object-fit: auto;
    object-position: center;
    width: 29px;
    margin-top: 86px;
    rotate:182deg;
  }

  .author {
    display: flex;
    margin-top: 41px;
    gap: 9px;
    font-size: 1rem;
    color: var(--textDefault);
    font-weight: 500;
    text-align: center;
  }

  .author-image {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 52px;
    border-radius: 50%;
  }
  .author-name {
    margin: auto 0;
  }

  .pizzaiconimg{
    max-width: 64px;
    position: absolute;
    left: 80%;
    margin-top: -4rem;
  }

  @media (max-width: 640px) {
    .review-container{
      margin-top: 4rem;
    }

    .review-content{
      flex-wrap: wrap;
      gap: 2rem;
    }

    .testimonial{
      
     min-width: 300px;
    }

    .author-image {
      width: 32px;
    }

    .testimonial-content {
      font-size: 16px;
    }


    .pizzaiconimg{
      max-width: 42px;
    }

    .star-icon {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      max-width: 24px;
    }

    .quote-icon-start {
      width: 12px;
    }

    .quote-icon-end {
      width: 12px;
     
    }
    .testimonial-text {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .review-header > h1{
      font-size: 1.5rem;
    }
  }