  .main-content {
      margin-top: 3rem;
  }

  .bynumbers-container{
    display: flex;
    flex-direction: row;
  }

  .content-wrapper {
    display: flex;
    flex-wrap: wrap-reverse;
    
  }

  .text-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    line-height: normal;
    min-width: 50%;
  }


  .text-content {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin: auto 0;
    
  }


  .title {
    color: var(--textDefault);
    font-weight: bold;
    font-size: 3rem;
  }

  .title .highlight {
    color: var(--primary);
  }


  .description {
    color: var(--textDefault);
    font-weight: normal;
    margin-top: -1rem;
    font-size: 1.3rem;
  }


  .button-group {
    align-self: start;
    display: flex;
    gap: 1rem;
    text-align: center;
    margin-top: 3rem;
  }

  .orderbtn{
    font-size: 1.1rem;
  }

  .aboutbtn{
    font-size: 1rem;
  }

  .image-column {
    flex-direction: column;
    line-height: normal;
    width: 100%;
    flex: 1;
    margin-left: 0;
   
  }

  .featured-image {
    object-fit: contain;
    object-position: center;

    display: flex;
    justify-content: center;
    max-width: 100%;
    min-width: 360px;
    flex-grow: 0;
    
  }


  .coffepic{
    max-width: 64px;
    position: absolute;
    top: 10rem;
    
    rotate: 15deg;
  }

  .onionpic{
    max-width: 64px;
    position: absolute;
    top: 650px;
    left: 45%;
    rotate: -23deg;
  }

  .carrotimg{
    max-width: 64px;
    position: absolute;
    right: 5%;
  }

  .leafimg{
    max-width: 64px;
    position: absolute;
    top: 600px;
    rotate: 180deg;
    right: 10%;
  }


  @media (max-width: 905px) {
      .image-column {
        display: flex;
        align-items: center;
      }
      .featured-image {
        max-width: 400px;
        min-width: auto;
      }

      .coffepic{
        position: absolute;
        top: 8rem;
        left: 10%;
       
      }

      .onionpic{
        top: 300px;
        left: 2rem;
        max-width: 42px;
      }

      .carrotimg{
        max-width: 42px;
      }

      .leafimg{
        max-width: 42px;
        top: 25rem;
      }
    
  
    }

    @media (max-width: 640px) {
      .featured-image {
        max-width: 300px;
        min-width: auto;
      }
      .main-content {
        margin-top: 2rem;
      }

      .text-column {
        margin-top: 4rem;
      }

      .coffepic{
        max-width: 42px;
      }
    }

    @media (max-width: 400px) {
      .featured-image {
        max-width: 280px;
        min-width: auto;
      }
      .main-content {
        margin-top: 1.2rem;
      }
      .carrotimg{
        right: 5%;
      }
    }
