.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 220px;
    height: 100%;
    background-color: var(--background);
    border-right: 1px solid #ccc;
    display: block;
  }
  
  .logo {
    padding: 20px;
  }
  
  .logo img {
    max-width: 100%;
  }
  
  .menu {
    overflow-y: auto;
    height: calc(100% - 290px); /* Adjust height to leave space for footer */
    
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
  }
  
  .menu li {
    padding: 10px;
  }
  
  .menu li {
    text-decoration: none;
    color: #333;
  }

  .menu li.active {
    text-decoration: none;
    color: white;
  }
  
  .menu li:hover {
    cursor:pointer;
    color: white;
    font-weight: bold;
    background-color: var(--secondary);
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--primary);
    
  }

  .footer > * {
    padding: 10px;
    color: white;
    font-weight: bold;
    text-align: left;
    line-height: 100%;
    margin:.7rem;
  }

  .sidebar-mobile{
    display: none;
    margin-bottom: 80px;
  }

  .footer-mobile{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--primary);
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    
  }

  .footer-mobile > *{
    margin: .5rem;
    color: white;
    
  }

  .footer-mobile > div {
    font-weight: normal;
    font-size: .7rem;
  }


  .menuitems-container{
    margin-top: 2rem;
    margin-left: 150px;
  }

  .menuitems-header{
    display:block;
    border-bottom: 1px solid gray;
    padding: 1rem 0rem;
    margin-bottom: 2rem;
   
  }

  h3{
    margin: 0;
    letter-spacing: 0;
    color: var(--textDefault);
  }

  .itemheader-content{

    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

  .sizes{
    display: flex;
    justify-content: flex-end;
    gap: 5.5rem;
    margin-right: 2rem;
    color: var(--textDefault);
    font-weight: normal;
  }

  .menuitems-content{
    display: flex;
    flex-direction: column;
    gap: 16px;

  }

  .navbarmenu{
    display: block;
    margin-top: 40px;
  }

  .sultesholder-desktop{
    display: flex;
    gap:7rem;
    justify-content: flex-end;
    margin-right: 2rem;
    font-weight: normal;

  }

  .active
  {
    background-color: var(--secondary);
    color: white;
    font-weight: bold;
  }

  @media (max-width: 900px) {
    .sidebar{
      max-width: 200px;
    }
    .logo img {
      max-width: 80%;
    }
  }

  @media (max-width: 780px) {
    
    .sultesholder-desktop{
      gap:4rem;
  
    }
}

  @media (max-width: 640px) {
    .sidebar{
      display: none;
      
    }

    .navbarmenu{
      display: none;
    }

    .menuitems-container{
      display:none;
    }

    .sidebar-mobile{
      display: block;
    }
  }


