.open-header{
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}
.open-header > h1{
  text-align: center;
    color: var(--textDefault);
}


.container {
    margin-top: 2rem;
}

.spiceimg{
  position: absolute;
  left: 20%;
  width:64px;
  height: 64px;
  margin-top: -4rem;
}

@media (max-width: 640px) {
  .spiceimg {
    width: 42px;
    height: 42px;
  }
}

  @media (max-width: 991px) {
    .container {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .content {
    display: flex;
    gap: 20px;
    
  }

  @media (max-width: 991px) {
    .content {
      flex-direction: column;
      gap: 0;
    }
  }

  .image-column-map {
    width: 100%;
  }

  @media (max-width: 991px) {
    .image-column-map {
      width: 100%;
    }
  }

  .main-image {
    width: 100%;
    height: 100%;

  }

  @media (max-width: 991px) {
    .main-image {
      margin-top: 40px;
    }
  }

  .info-column {
    width: 100%;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 991px) {
    .info-column {
      width: 100%;
      margin: 40px 0 0;
    }
  }

  .holiday-notice {
    background-color: var(--backgroundSecondary);
    border-radius: 20px;
    padding: 14px 22px;
    margin-bottom: 2rem;
  }

  @media (max-width: 991px) {
    .holiday-notice {
      padding: 14px 20px;
    }
  }

  .holiday-title {
    color: var(--textDefault);
    font-weight: bold;
    font-size: 1.2rem;
  }

  .holiday-text {
    color: var(--secondary);
    font-weight: bold;
    font-size: 1rem;
    margin-top: 14px;
  }

  .closed-text {
    color: var(--secondary);
    font-weight: 800;
  }

  .opening-hours-section {
    margin: 0px 0px 0px 22px;
  }

  @media (max-width: 991px) {
    .opening-hours-section {
      margin: 0px 0 0 10px;
    }
  }

  .section-title {
    color: var(--textDefault);
    font-size: 1.5rem;
   
    margin-top: 0px;
  }

  .opening-hours {
    margin-top: 25px;
    font-size: 24px;

  }

  .hours-list {

    display: flex;
    justify-content: space-between;
  }
  
  @media (max-width: 991px) {
    .hours-list {
      margin-right: 5px;
    }
  }

  .weekdays {
    color: var(--textDefault);
    
  }

  .weekday {
    margin-top: 19px;
  }

  .weekday:first-child {
    margin-top: 0;
  }

  .hours {
    color: var(--primary);
    font-weight: bold;
  }

  .hour {
    margin-top: 20px;
  }

  .hour:first-child {
    margin-top: 0;
  }

  .closed {
    color: var(--secondary);
    padding-left: 43px;
    text-align: right;
  }

  @media (max-width: 991px) {
    .closed {
      padding-left: 20px;
    }
  }

  .address {
    background-color: var(--backgroundSecondary);
    border-radius: 20px;
    padding: 16px 23px;
    display: flex;
    align-items: center;
    gap: 18px;
    margin-top: 37px;
    margin-left: -0.5rem;
    font-size: 1.2rem;
    color: var(--textDefault);
  }

  @media (max-width: 991px) {
    .address {
      padding: 16px 20px;
    }
  }

  .location-icon {
    width: 45px;
    aspect-ratio: 1;
  }

