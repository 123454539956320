  .stats-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 7rem;
    gap: 2rem;
  }

  .stat-item {
    display: flex;
    gap: 15px;
    padding: 0 1px;
  }

  .stat-icon {
    object-fit: auto;
    object-position: center;
    width: 63px;
    height: 63px;
  }

  .stat-content {
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }

  .stat-value {
    color: var(--primary);
    font-weight: bold;
    font-size: 1.5rem;
  }

  .stat-label {
    color: var(--textDefault);
    text-align: center;
    margin-top: 3px;
    font-weight: normal;
    font-size: 1rem;
  }

  @media (max-width: 640px) {
    .stats-container {
      margin-top: 10rem;
      
    }
  }
  