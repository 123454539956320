.delivery-section {
    background-color: var(--backgroundSecondary);
    align-self: stretch;
    display: flex;
    margin-top: 6rem;
    border-radius: 15px;
    flex-direction: column;
    padding-left: 0;
  }

  .delivery-header{
    padding-left: 0;
  }


  .title-menu {
    width: 100%;
    
    color: var(--textDefault);
    text-align: center;
    align-self: center;
    font-size: 2rem;
   
  }

  .description-menu {
    padding: .5rem;
    color: var(--textDefault);
    text-align: center;
    align-self: center;
    margin-top: -1rem;

  }

  .order-info {
    color: var(--textDefault);
    font-size: 1.2rem;
    padding-left: 2rem;
  }

  .delivery-title {
    color: var(--textDefault);
    margin-top: 1rem;
    padding-left: 2rem;
  }

  .delivery-columns {
    padding-left: 2rem;
    justify-content: space-between;
    display: flex;
    padding-right: 2rem;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .delivery-columns:last-child{
      max-width: 100%;
      
  }


  .delivery-column {
    
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    line-height: normal;
    flex:1;
    
    
    
  }

  .img-col {
    
      min-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    
  }



  .delivery-info-container {
   
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    font-size: 1.1rem;
    line-height: 100%;
    
  }

  .delivery-info {
   
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
  .delivery-location {
    
    max-width: 50%;
    display: flex;
    flex-direction: column;
    color: var(--textDefault);
    font-weight: 500;
    margin-top: 37px;
  }
  .delivery-price {
   
    align-self: center;
    flex-direction: column;
    color: var(--primary);
    font-weight: 800;
    text-align: right;
    margin-top: 37px;
    flex-shrink: 0;
  }

  .menu-button {
    border-radius: 10px;
    background-color: var(--primary);
    margin-top: 2rem;
    color: var(--textWhite);
    justify-content: center;
    padding: 19px 23px;
  }

  .delivery-image {
    object-fit: auto;
    object-position: center;
    width: 319px;
    max-width: 100%;
  }

  .menubtn-container{
    
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .menu-button
  {
    font-size: 1rem;
  }
