.item-container{
    background-color: var(--backgroundSecondary);
    padding: 0.5rem 1rem;
    border-radius: 15px;
}

.item-name{
    margin:  0;
    color: var(--textDefault);
    font-weight: bold;
    
}

.item-header{
    display: flex;
    justify-content: space-between;
    
}
.prices{
    font-size: 1.2rem;
    display: flex;
    font-weight: bold;
    color: var(--primary);
    gap:4rem;
    flex-shrink: 0;
}

.item-ingredients{
    padding: .5rem 1.2rem;
    font-weight: normal;
}
.item-ingredients > span{
    color: var(--textDefault);
}

@media (max-width: 780px) {
    
    .prices{
        font-size: 1rem;
        gap:2rem;
        flex-shrink: 0;
    }
}

