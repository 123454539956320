.info-container{
    margin-top: 2rem;
    background-color: var(--backgroundSecondary);
    padding: 1rem;
    border-radius: 16px;
    margin-bottom: 2rem;
}

.centeredText{
    text-align: center;
}

.pizzadata-title{
    line-height: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.pizzadata-title > h3{
    flex-shrink: 0;
}

.pizzadata-sizes{
    display: flex;
    gap:6rem;
}

.pizzadata-prices{
    display: flex;
    gap:4rem;
}

.gmdata{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gm-inline{
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    gap: 3rem;
}

@media (max-width: 720px) {
    
    .pizzadata-prices{
        gap:2rem;
    }

    .pizzadata-sizes{
        gap:4rem;
    }
}