.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.1rem;
    color: var(--textDefault);
    font-weight: normal;
    
  }


  .logo {
    width: 150px;
    
    object-fit: contain;
    object-position: center;
  }

  .logoNavbar{
    max-width: 9rem;
    object-fit: contain;
    object-position: center;
  }

  .nav-right{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    font-weight: 400;
}

.nav-list{
    display: flex;
    list-style-type: none;
    gap:2rem;
    
}


.nav-icon{
  display: none;
}

.iconnavimg{
  object-fit: center;
  width: 42px;
  height: 42px;
}

@media (max-width: 768px){
  .nav-icon{
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .nav-list{
      display:none;
      background-color: var(--background);
      flex-direction: column;
      position: absolute;
      top:6rem;
      right:2rem;
      padding:2rem;
      align-items: flex-start;
      border-radius: 10px;
      box-shadow: var(--boxShadow);
      z-index: 10;
  }
}

  @media (max-width: 640px) {
    .logo {
      width: 100px;
      max-width: 100%;
    }
  }

  .nav-item {
    text-decoration: none;
    color:var(--textDefault);
    white-space: nowrap;
    cursor:pointer;
  }

  .nav-item:hover {
    color:var(--secondary);
  }