:root
{
  --primary: #29381B;
  --secondary : #DEA15E;
  --accent : #509f7c;
  --black: #1b1b1b;
  --background : #FDFBE4;
  --backgroundSecondary: #F3F1DA;
  --textDefault: #373934;
  --textWhite: #F2F2F2;
  --paddingSides: 4rem;
  --boxShadow : 0px 19px 60px rgba(10, 8, 8, 0.08);
  --boxShadowGreen: #509f7c6b;
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
   font-size: 18px;
   background-color: var(--background);
}



.app-content{
  width: 80vw;
  margin: 0 auto;
}

@font-face {
  font-family: 'raleway';
  src: url('./fonts/Raleway.ttf') format('truetype');
}

@font-face {
  font-family: 'montserrat';
  src: url('./fonts/Montserrat.ttf') format('truetype');
}

body > * {
  font-family: 'raleway', sans-serif;
  font-weight: normal;
 
}



@media (max-width: 640px) {
  :root {
    font-size: 15px;
  }
  .app-content{
    width: 90vw;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  :root {
    font-size: 12px;
  }
}

@media (min-width: 2300px) {
  :root {
    font-size: 26px;
  }
}

@media (min-width: 2300px) {
  :root {
    font-size: 40px;
  }
}

.button-primary{
  display: inline-block;
  padding: 0.5em 1.7em;
  margin: 0 0.1em 0.1em 0;
  border-radius: 1em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 600;
  font-family: 'raleway', sans-serif;
  color: white;
  background-color: var(--primary);
  text-align: center;
  transition: all 0.1s;
  border: 2px solid var(--primary);
}

.button-primary:hover {
  color: var(--textDefault);
  background-color: var(--background);
  border: 2px solid var(--primary);
}


.button-secondary{
  display: inline-block;
  padding: 0.5em 1.7em;
  margin: 0 0.1em 0.1em 0;
  border-radius: 1em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 500;
  font-family: 'raleway', sans-serif;
  color: var(--textDefault);
  background-color: var(--background);
  text-align: center;
  transition: all 0.1s;
  border: 2px solid var(--secondary);
}

.button-secondary:hover {
  color: white;
  background-color: var(--secondary);
  border: 2px solid var(--secondary);
}

.numberFont{
  font-family: "montserrat", sans-serif;
}

