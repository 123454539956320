.tab-navigation {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    width: 100%;
    margin-top: 2rem;
  }
  
  .tabs {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
    padding-bottom: 10px;
  }
  
  .tab {
    padding: 15px 20px;
    flex-shrink: 0;
    margin-right: 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent; /* Initially no border */
    line-height: 100%;
  }
  
  .tab.active {
   background-color: var(--background);
   color: var(--secondary);
    border-radius: 30px;
  }
  
  .tab-content {
    margin-top: 10px;
    background-color: var(--background);
  }
  
  .tab-pane {
    display: none; /* Hide tab content by default */
  }
  
  .tab-pane.active {
    display: flex; /* Show active tab content */
    flex-direction: column;
    gap: 16px;
    background-color: var(--background);
    font-weight: normal;
  }

  .pizzaholder{
    display: flex;
    gap:3rem;
    justify-content: flex-end;
    margin-right: 2rem;
  }

  .sultesholder{
    display: flex;
    gap:5rem;
    justify-content: flex-end;
    margin-right: 2rem;
  }