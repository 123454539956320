.about-container{
    
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.about-header{
    width: 100%;
    text-align: center;
    color: var(--textDefault);
    margin-top: 4rem;
}

.about-img{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgab{
    
    object-fit: contain;
    object-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-grow: 0;
}

.about-content{
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--backgroundSecondary);
    border-radius: 16px;
}

.welcometext{
    font-weight: bold;
    color: var(--primary);
    margin-bottom: 0rem;
}

.about-content > div > h2{
    color: var(--primary);
    margin-bottom: 0;
}

.about-content > div > span{
    font-style: italic;
}

.about-content > div > p{
    font-size: 1.1rem;
    color: var(--textDefault);
}

.about-highlight{
    font-size: 1.2rem;
    font-weight: bold;
    color:var(--primary);
}

@media (max-width: 900px) {
    .about-container{
        flex-direction: column;
        align-items: center;
    }
  }

